

.footer{
   display: flex;
   flex-direction: row;
   justify-content: center;
   background-color: black  ;

   
}
.footerContainer
{   
    display: flex;
    flex-direction: column;
    border-width: 4px;
    border-color: #180b0b;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    margin-top: 20px;
    width: 420px;
    animation: fadeIn 5s; 

}
.footerLogoContainer
{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 20px;
}
.footerLogo{
    justify-self: center;
  
}
.footerHeader{
    text-align: center;
   
}
.quickLinksText{
    text-align: center;
    color: white;
}
.quickLinksHeadText{
    text-align: center;
    color: white;
}
@media only screen and (min-width: 708px)   /*  Mobile Phone View View Screen*/
{
.footerContainer
{  
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin-left: 10px;
    margin-Right: 10px;  
    border-width: 4px;
    border-color: #180b0b;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    margin-top: 20px;
    width: 1750px;
    overflow: hidden;
    animation: fadeIn 5s; 

}
.footerLogoContainer
{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

}
.quickLinksText{
    text-align:left;
    margin-top: -10px;
    color: white;
}
.footerLogo{
    justify-self: center;
    margin-bottom: -20px;
}
}

